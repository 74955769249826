import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import * as styles from "../../components/style/thanks.module.css"

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from '../../components/logo';
import axios from "axios";
import _ from "lodash"
import {navigate} from 'gatsby'; //import navigate from gatsby

const {GATSBY_CHECK_RESULTS} = process.env;

const ThankYouPage = (props) => {
	const [ questionarieData, setQuestionarieData ] = React.useState({'request':false});
	const [ countCheck, setcountCheck ] = React.useState(0);

	const access_code = props.params.access_code

	const checkQuestionarieResult = React.useCallback(async () => {
		var requestData = {access_code : access_code ,
					  	   type : 'check_result'
					  	}
			
		await axios.get(GATSBY_CHECK_RESULTS , { params: requestData })
				.then((res) => {
					setQuestionarieData(res.data);
				})
				.catch((error) => { 
					var result = {'request' : false};
					if (typeof error.response.data === 'object') {
						if(error.response.data.errors) {
							result.errors = _.flatten(_.toArray(error.response.data.errors));
						} else if (error.response.data.message) {
							result.errors = [error.response.data.message];
						}
					} else {
						result.errors = ['Something went wrong. Please try again.'];
					}
					setQuestionarieData(result);
				});
	},[access_code])

	React.useEffect( () => {
		if((questionarieData.request && questionarieData.request === true) || countCheck > 6) {
			if(questionarieData.request && questionarieData.status) {
				navigate('/gel/' + access_code);
			}
			return // stop
		}

		var interval = setInterval(() => {
				checkQuestionarieResult()
				setcountCheck((prev) => prev + 1);
		}, 5000); // every 5 seconds
		return () => clearInterval(interval)
	  }, [questionarieData,access_code,countCheck,checkQuestionarieResult]) // execute in the first render

	return (
		<>
		<Layout>
			<Container fluid className="g-0">
				<Row className="g-0 min-vh-100">
					<Col xs="12" lg="6">
						<div className={'min-vh-100 banner-size-page ' + styles.bgHome}></div>
					</Col>
					<Col xs="12" lg="6">
						<Row className="justify-content-center h-100 g-0">
							<Col xs="12" md="10" lg="10" xl="8">
								<div className="py-5 h-100 d-flex flex-column">
									<div className="text-center pt-3"><Logo/></div>
									<div className="d-flex flex-column h-100 justify-content-center">
										<div className="text-center">
											<h1>Thank you for completing the survey.</h1>
										</div>

										<div className="text-center py-2">
											<h4>We are building your personalized formula...</h4>
											<div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
												<span className="visually-hidden">Loading...</span>
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	</Layout>
	</>
	)
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Thank you" />

export default ThankYouPage
